.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.title-container .title {
  /* color: #111111; */
  font-size: 2.2em;
  letter-spacing: 2px;
}

.title-container .horizontal-bar {
  height: 5px;
  width: 60px;
  /* background-color: #de3425; */
  border-radius: 2px;
}

@media (min-width: 767px) {
  .title-container .title {
    font-size: 2.7em;
  }
}

@media (max-width: 1050px) {
}
