.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-container .contact-content {
  width: 100%;
  padding: var(--mobile-padding);
  margin: var(--section-margin);
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: center;
}

.title-container.contact-title {
  margin-bottom: 30px;
}

.contact-container .secondary-title-container {
  height: 35px;
  position: relative;
  margin-bottom: 20px;
}

.contact-container .secondary-title-container .text {
  padding-left: 5px;
  font-family: "SourceSansProSemiBold", sans-serif;
  font-size: 1.2em;
}

.contact-container .secondary-title-container .circle {
  position: absolute;
  bottom: 2px;
  width: 9px;
  height: 9px;
  border-radius: 99px;
}

.contact-container .secondary-title-container .line {
  width: 200px;
  height: 3px;

  position: absolute;
  bottom: 2px;
  left: 4px;
  border-radius: 99px;
}

.contact-container .secondary-title-container .circle,
.contact-container .secondary-title-container .line {
  background-color: #de3425;
}

.contact-container .social-container {
  align-self: center;
  border-radius: 5px;
  background-color: transparent;
  padding: 4px;
  display: flex;
  gap: 20px;
}

.contact-container .social-container .entry {
  box-shadow: rgba(100, 100, 111, 0.2) 0 2px 10px 0;

  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  transition: 0.2s ease;
}

.contact-container .social-container .entry .icon {
  width: 35px;
  height: 35px;

  transition: 0.2s ease-out;
}

.contact-container .social-container .entry:hover .icon.linked-in,
.contact-container .social-container .entry:hover .icon.twitter {
  color: #2996e8;
}

.contact-container .social-container .entry:hover,
.contact-container form button:hover {
  transform: scale(1.05);
}

.contact-container div.divider-container {
  display: flex;
  gap: 10px;
  align-items: center;
  align-self: center;
}

.contact-container div.divider-container .or {
  font-family: "SourceSansProRegular", sans-serif;
}

.contact-container div.divider-container .line {
  height: 1px;
  width: 70px;
}

.contact-container .form-container {
  max-width: 700px;

  width: 100%;
  padding: 12px;

  box-shadow: rgba(100, 100, 111, 0.2) 0 2px 10px 0;
  position: relative;

  border-radius: 5px;
}

.contact-container form .loading-container {
  width: 100%;
  height: 0px;

  background-color: rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.4s ease-in;
  z-index: 99;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container form .success,
.contact-container form .fail {
  /* position: absolute; */
  font-size: 1.2em;
  /* background-color: #ffffff; */
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 20px -7px #0000001f;
}

.contact-container form div.fail {
  border: 1px solid red;
}

.contact-container form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact-container form input {
  border-radius: 5px;
  width: 100%;
  height: 56px;
  padding: 7px;
  padding-top: 20px;
  font-size: 1.1em;

  border-style: solid;
  outline: none;
  transition: 0.2s ease;
  font-family: "SourceSansProRegular", sans-serif;
}

.contact-container form input:focus,
.contact-container form textarea:focus {
  border: 1px solid #607393;
}

.contact-container form div {
  position: relative;
}

.contact-container form .label {
  pointer-events: none;
  /* color: #666666; */
  font-family: "SourceSansProSemiBold", sans-serif;

  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
  transition: 0.2s ease;
}

.contact-container form input:not(:placeholder-shown) + .label,
.contact-container form input:focus + .label {
  top: 10px;
  left: 7px;
  font-size: 1em;
}

.contact-container textarea.message {
  outline: none;
  width: 100%;
  height: 150px;
  resize: none;
  padding: 7px;
  font-size: 1.1em;
  padding-top: 20px;

  border-style: solid;
  transition: 0.2s ease;
  border-radius: 5px;
  font-family: "SourceSansProRegular", sans-serif;
}

.contact-container .message + .label {
  top: 20px;
}

.contact-container form .message:not(:placeholder-shown) + .label,
.contact-container form .message:focus + .label {
  top: 10px;
  left: 7px;
  font-size: 1em;
}

.contact-container form button {
  height: 56px;
  align-self: baseline;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  border-radius: 5px;
  border: none;
  font-size: 1.1em;
  font-family: "SourceSansProSemiBold", sans-serif;
  transition: 0.2s ease;
}

.contact-container .social-container .entry .text {
  display: none;
}

.contact-container .social-container .entry,
.contact-container form button {
  cursor: pointer;
}

@media (min-width: 767px) {
  .contact-container .contact-content {
    max-width: var(--desktop-max-width);
    padding: var(--desktop-padding);
  }

  .contact-container .social-container .entry {
    width: 120px;
    height: 50px;
  }

  .contact-container .social-container .entry .icon {
    width: 35px;
    height: 35px;
  }

  .contact-container .social-container .entry .text {
    display: block;
    font-size: 1.2em;
    font-family: "SourceSansProSemiBold", sans-serif;
  }

  .contact-container form button {
    width: 150px;
    align-self: flex-end;
  }
}

/* loading-spinner  */

.app-container div.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.loading .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.loading div {
  position: absolute;
  width: 90px;
  height: 51px;
}

div.loading div:nth-of-type(2) {
  transform: rotate(60deg);
}
div.loading div:nth-of-type(3) {
  transform: rotate(-60deg);
}

div.loading div div {
  width: 100%;
  height: 100%;
  position: relative;
}

div.loading div div span {
  position: absolute;
  width: 4px;
  height: 0%;
  background: #053146;
  z-index: 999999;
}

div.loading .h1 {
  left: 0;
  animation: load1 7.2s ease infinite;
}
div.loading .h2 {
  right: 0;
  animation: load2 7.2s ease 0.6s infinite;
}
div.loading .h3 {
  right: 0;
  animation: load3 7.2s ease 1.2s infinite;
}
div.loading .h4 {
  right: 0;
  animation: load4 7.2s ease 1.8s infinite;
}
div.loading .h5 {
  left: 0;
  animation: load5 7.2s ease 2.4s infinite;
}
div.loading .h6 {
  left: 0;
  animation: load6 7.2s ease 3s infinite;
}

@keyframes load1 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.944444433% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load2 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.944444433% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%} */
}

@keyframes load3 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.94444443% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load4 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.94444443% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load5 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.94444443% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load6 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.94444443% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}
