.projects-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 20px -7px #0000001f;
}

.projects-container .projects-content {
  padding: var(--mobile-padding);
  margin: var(--section-margin);
}

div.title-container.projects-title {
  margin-bottom: 35px;
}

.projects-container .card-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.projects-container .card-container .project-card-container {
  flex: 1;
}

.projects-container div.line {
  height: 1px;
  width: 90%;
  /* background-color: #e8e8e8; */
}

@media (min-width: 767px) {
  .projects-container .projects-content {
    max-width: var(--desktop-max-width);
    padding: var(--desktop-padding);
  }
}
