.project-entry-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-entry-container img {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.project-entry-container .title {
  font-size: 1.7em;
  margin-bottom: 20px;

  letter-spacing: 1px;
}

.project-entry-container .info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-entry-container .description {
  font-family: "SourceSansProRegular", sans-serif;
  /* color: #666666; */

  font-size: 1.2em;
  line-height: 28px;
  margin-bottom: 30px;
}

.project-entry-container .button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.project-entry-container .button-container button {
  position: relative;
  padding: 10px 28px 10px 28px;
  /* background-color: #fad42c; */
  border: none;
  /* color: #fad42c; */
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  transition: 0.2s ease-in;
  cursor: pointer;
}

.project-entry-container .button-container button:hover {
  transform: translateY(-2px);
}

.project-entry-container .button-container button span {
  font-size: 1.4em;
  /* color: white; */
  font-family: "SourceSansProSemiBold", sans-serif;
}

.project-entry-container .button-container button .icon {
  position: absolute;
  right: 0;
  height: 28px;
  width: auto;
  transform: translate(6px, 7px) rotate(-45deg);
  /* color: #2c2c2c60; */
}

@media (min-width: 767px) {
  .project-entry-container {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
  }

  .project-entry-container div.button-container button .icon {
    transform: translate(5px, 12px) rotate(-45deg);
  }

  .project-entry-container img {
    grid-column: 1/2;
  }

  .project-entry-container .info-container {
    grid-column: 2/3;
    align-items: flex-start;
    margin-left: 20px;
  }

  .project-entry-container .description {
    text-align: start;
  }

  .project-entry-container .button-container {
    justify-content: flex-start;
    gap: 30px;
  }

  .project-entry-container .info-container .title {
    font-size: 1.9em;
  }

  .project-entry-container .button-container button span {
    font-size: 1.5em;
  }

 
}

@media (min-width: 1050px) {
  .project-entry-container {
    column-gap: 40px;
  }

  .project-entry-container .description {
    font-size: 1.4em;
  }
}
