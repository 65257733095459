@font-face {
  font-family: "SourceSansPro";
  src: local("SourceSansPro"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-Black.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansProRegular";
  src: local("SourceSansPro-Regular"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "SourceSansProSemiBold";
  src: local("SourceSansPro-SemiBold"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf)
      format("truetype");
}

* {
  font-family: "SourceSansPro", sans-serif;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
}

.ball {
  position: fixed;
  border-radius: 100%;
  opacity: 0.4;
  z-index: -99;
}
