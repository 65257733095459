.mobile-menu-container {
  min-width: 100vw;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 999;

  display: flex;
  flex-direction: column;
  font-family: inherit;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.119);
}

.mobile-menu-container button.menu-entry {
  width: 100%;
  /* background-color: #e6e6e6 !important; */
  text-align: end;
  padding: 15px;
  height: 60px;
  cursor: pointer;
  font-size: 1.1em;
  border: none;

  border-top: 1px solid #8787878f;
  transition: 0.2s ease;
  z-index: 5;
}

.mobile-menu-container button.menu-entry:hover,
.mobile-menu-container button.menu-entry:active {
  color: #e45447;
}
