.desktop-menu-container .button-container {
  display: flex;
}

.desktop-menu-container .button-container .menu-button {
  height: 36px;
  padding: 0px 12px 0px 12px;
  font-size: 1em;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.3em;
  transition: 0.15s ease;
  cursor: pointer;
}

.desktop-menu-container .button-container .menu-button:hover,
.desktop-menu-container .button-container .menu-button:active {
  color: #e45447;
}
