.hi-bubble-container {
  text-align: center;
  font-size: 1.5em;
  background-color: #575454;
  color: #f2f0ee;
  position: relative;
  padding: 10px;
  border-radius: 3px;
  z-index: 0;
  transform: scale(1);
}

.arrow-down {
  position: absolute;
  top: 100%;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #575454;
}

@media (min-width: 767px) {
  .hi-bubble-container {
    font-size: 2em;
  }
}

@media (max-width: 1050px) {
  
}

