.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0 -3px 20px -7px #0000001f;
}

.about-me-container .about-me-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: var(--mobile-padding);
  margin: var(--section-margin);
}

.about-me-container .summary-container {
  margin-bottom: 20px;
  line-height: 32px;
  font-size: 1.25em;

  font-family: "SourceSansProRegular", sans-serif;
}

.about-me-container .title-container.skills {
  margin: 35px 0px 15px 0px;
}

.about-me-container div.title-container.about-me {
  margin: 25px 0px 25px 0px;
}

@media (min-width: 768px) {
  .about-me-container .about-me-content {
    display: grid;
    column-gap: 50px;
    align-items: baseline;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 65px auto;

    padding: var(--desktop-padding);
    max-width: var(--desktop-max-width);
  }

  .about-me-container .title-container.about-me {
    color: red;
    align-self: center;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .about-me-container div.title-container.about-me {
    margin: 0;
  }

  .about-me-container .skills-container {
    grid-column: 2 /3;
    grid-row: 2/3;
  }

  .about-me-container .summary-container {
    /* text-align: justify; */
    grid-column: 1 /2;

    grid-row: 2/3;
  }

  .about-me-container .title-container.skills {
    display: block;
  }

  .about-me-container .title-container.skills h2 {
    font-size: 1.7em;
    font-family: "SourceSansProSemiBold", sans-serif;
  }

  .about-me-container .title-container.skills .horizontal-bar {
    display: none;
  }
}

@media (min-width: 1050px) {
  .about-me-container .about-me-content {
    column-gap: 100px;
  }

  .about-me-container .summary-container {


    font-size: 1.4em;
    line-height: 36px;

  }
}
