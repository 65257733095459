:root {
  --desktop-max-width: 1350px;
  --desktop-padding: 0px 50px 0px 50px;
  --mobile-padding: 0px 18px 0px 18px;
  --section-margin: 35px 0px 35px 0px;
}

.app-container {
  padding-top: 50px;
  overflow-y: scroll;
  overflow-x: hidden !important;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  background-attachment: fixed;
  background-size: cover;
}

#about-me,
#projects,
#contact {
  scroll-margin-top: 80px;
}

.app-container .app-content {
  min-width: 100vw;

  /* background-color: white; */
}

.app-container > .loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

@media (min-width: 767px) {
  .app-container .app-content {
    flex: 1;

    border-radius: 0.5rem;
    min-width: auto;
  }
}

@media (max-width: 1050px) {
  .app-container .app-content {
    margin-top: 0;
  }
}

/* loading-spinner  */

.app-container div.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.loading .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.loading div {
  position: absolute;
  width: 90px;
  height: 51px;
}

div.loading div:nth-of-type(2) {
  transform: rotate(60deg);
}
div.loading div:nth-of-type(3) {
  transform: rotate(-60deg);
}

div.loading div div {
  width: 100%;
  height: 100%;
  position: relative;
}

div.loading div div span {
  position: absolute;
  width: 4px;
  height: 0%;
  background: #053146;
  z-index: 999999;
}

div.loading .h1 {
  left: 0;
  animation: load1 7.2s ease infinite;
}
div.loading .h2 {
  right: 0;
  animation: load2 7.2s ease 0.6s infinite;
}
div.loading .h3 {
  right: 0;
  animation: load3 7.2s ease 1.2s infinite;
}
div.loading .h4 {
  right: 0;
  animation: load4 7.2s ease 1.8s infinite;
}
div.loading .h5 {
  left: 0;
  animation: load5 7.2s ease 2.4s infinite;
}
div.loading .h6 {
  left: 0;
  animation: load6 7.2s ease 3s infinite;
}

@keyframes load1 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.944444433% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load2 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.944444433% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%} */
}

@keyframes load3 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.94444443% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load4 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.94444443% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load5 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.94444443% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load6 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.94444443% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}
