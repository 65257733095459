.footer-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container .footer-content {
  max-width: var(--desktop-max-width);
  padding: var(--mobile-padding);
  padding-top: 40px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container .horizontal-wrapper {
  display: flex;
  gap: 50px;
  width: 100%;
  flex-direction: column;
}

.footer-container .button-container {
  display: flex;
  justify-content: space-around;
}

.footer-container .button-container .entry {
  font-size: 0.9em;
  font-family: "SourceSansProRegular", sans-serif;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  letter-spacing: 1px;
}

.footer-container .footer-content .text-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
}

.footer-container .footer-content .text-container .logo {
  width: 85px;
}

.footer-container .footer-content .text-container .text {
  font-family: "SourceSansProSemiRegular", sans-serif;
  line-height: 22px;
}

.footer-container .line {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background-color: #444444;
}
.footer-container .rights {
  color: white;
  font-family: "SourceSansProSemiBold", sans-serif;
  align-self: center;
  font-size: 0.8em;
}

.footer-container .email {

  font-family: "SourceSansProRegular", sans-serif;
  margin-top: 5px;
  color: #cacaca;
}

@media (min-width: 767px) {
  .footer-container .horizontal-wrapper {
    flex-direction: row;
  }

  .footer-container .text-container {
    max-width: 400px;
  }
  .footer-container .footer-content .text-container .logo {
    width: 95px;
  }

  .footer-container .footer-content .text-container .text {
    font-size: 1.2em;
    line-height: 30px;
    color: #efefef;
    font-family: "SourceSansProSemiBold", sans-serif;
  }

  .footer-container .button-container {
    margin-left: auto;
    flex-direction: column;
    align-items: flex-end;
  }

  .footer-container .button-container .entry {
    font-size: 1em;
  }

  .footer-container .button-container .entry:hover {
    color: #e45447;
  }

  .footer-container .rights {
    font-size: 1em;
  }
}

@media (max-width: 1050px) {
}
