.landing-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.landing-screen-container .landing-screen-content {
  display: flex;
  height: max(calc(100vh - 50px), 550px);
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.landing-screen-container .name-container .name {

  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 40px;
  font-size: 3em;
  margin-right: 33px;
}

.landing-screen-container .name {
  display: flex;
  flex-direction: column;
}

.landing-screen-container .name span {
  display: flex;
}

.landing-screen-container .title-container .title {
  font-size: 1.6em;
  letter-spacing: 0px;
  /* position: absolute; */
  font-family: "SourceSansProSemiBold", sans-serif;
}

.landing-screen-container .title-container .Typewriter__cursor {
  margin-left: -4px;

  font-family: "Courier New", Courier, monospace;
  font-size: 1.5em;
}



.landing-screen-container .text-wrapper {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-height: 770px) {
  .landing-screen-container .text-wrapper {
    margin-top: 20px;
  }
}

@media (max-height: 670px) {
  .landing-screen-container .text-wrapper {
    margin-top: 0px;
  }

  .landing-screen-container div.scroll-down-icon {
    bottom: 0px;
  }
}

.landing-screen-container .photo-container img {
  width: 160px;
  height: auto;
  margin-top: 50px;
}

.landing-screen-container button.social {
  border: none;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: 0.2s ease;
}

.landing-screen-container .social-link-container .icon {
  width: 30px;
  height: 30px;
}
.landing-screen-container .social-link-container {
  display: none;
}

.landing-screen-container .scroll-down-icon {
  bottom: 20px;
  position: absolute;
}

.landing-screen-container .inner-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

@media (min-width: 767px) {
  .landing-screen-container .landing-screen-content {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
    flex-direction: row;
    padding-bottom: 150px;

    padding: var(--desktop-padding);
    max-width: var(--desktop-max-width);
  }

  .landing-screen-container .title-container {
    font-size: 1.5em;
  }

  .landing-screen-container {
    height: max(calc(100vh - 44px), 550px);
  }

  .landing-screen-container .name-container .name {
    font-size: 5rem;
    line-height: 59px;
    padding: 0;
    margin-left: -5px;
    margin-right: 25px;
  }

  .landing-screen-container .text-wrapper {
    flex: 1;
    align-items: center;
  }

  .landing-screen-container .photo-container {
    flex: 1;
  }

  .landing-screen-container .photo-container img {
    width: 320px;
    height: auto;
  }

  .landing-screen-container .scroll-down-icon {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .landing-screen-container .photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .landing-screen-container .landing-screen-content {
    padding: 0px 0px 150px 0px;
  }

  .landing-screen-container .social-link-container {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 3px;
    box-shadow: rgba(0, 0, 0, 0.137) 0 7px 29px 0;
    position: absolute;
    left: 0;
  }

  .landing-screen-container button.social:hover {
    background: rgba(250, 207, 15, 0.2);
  }
}

/* scroll icon */

.landing-screen-container .scroll-down-icon a div {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;

  border-style: solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

.landing-screen-container .scroll-down-icon a {
  text-decoration: none;

  padding-top: 50px;
  display: block;
  position: relative;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}
/*                     */
