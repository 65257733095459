.top-bar-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  width: 100%;
  /* background-color: #0e1829f5 !important; */
  white-space: #ffffffca;
  z-index: 9;
  /* box-shadow: 0px 0px 40px -10px rgba(255, 255, 255, 0.47); */
}

.top-bar-container .top-bar-content {
  display: flex;
  align-items: center;
  padding: 7px 0px 7px 0px;

  padding: var(--mobile-padding);
}

.top-bar-container .title {
  /* font-size: 2.1em; */
  width: 80px;
  height: auto;
}

.top-bar-container div.dark-light-icon {
  margin-left: 24px;
  height: 27px;
  width: 27px;
  position: relative;
}

.top-bar-container div.dark-light-icon .dark-icon,
.top-bar-container div.dark-light-icon .light-icon {
  top: -5px;
  width: 37px;
  height: 37px;
  position: absolute;
  margin-bottom: -5px;
  transition: 0.15s ease;
padding: 4px;
  

cursor: pointer;
}

@media (min-width: 750px) {
  .top-bar-container div.dark-light-icon .dark-icon:hover,
  .top-bar-container div.dark-light-icon .light-icon:hover {
    transform: rotate(10deg);
    color: #e45447;
  }
  .top-bar-container {
    align-items: center;
  }

  .top-bar-container .top-bar-content {
    width: 100%;
    /* max-width: var(--desktop-max-width); */
    padding: var(--desktop-padding);
  }
}

.top-bar-container .menu-icon {
  transform: scale(0.5);
  margin-right: 0;
  margin-left: auto;
  margin-right: -12px;
  z-index: 9999;
}

.top-bar-container .desktop-menu-container {
  display: none;
}

@media (min-width: 750px) {
  .top-bar-container .menu-icon {
    display: none;
  }

  .top-bar-container .desktop-menu-container {
    display: block;
    margin-left: auto;
  }

  .top-bar-container {
    padding: 10px 0px 10px 0px;
  }

  .top-bar-container .title {
    /* font-size: 2.5em; */
    width: 80px;
    height: auto;
  }
}

/* menu icon animation */

#menu_checkbox {
  display: none;
}

label {
  display: block;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

label:before {
  content: "";
  position: static;
  background-color: #f44336;
  transition: 1.2s cubic-bezier(0, 0.96, 1, 0.02) background-color;
}

label div {
  position: relative;
  top: 0;
  height: 12px;
  background-color: rgb(255, 0, 0);
  margin-bottom: 12px;
  transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
    0.3s ease right;
  border-radius: 2px;
}

label div:first-child {
  transform-origin: 0;
}

label div:last-child {
  margin-bottom: 0;
  transform-origin: 60px;
}

label div:nth-child(2) {
  right: 0;
  width: 60px;
}

#menu_checkbox:checked + label:before {
  background-color: #4caf50;
}

#menu_checkbox:checked + label div:first-child {
  top: -6px;
  transform: rotateZ(45deg);
}

#menu_checkbox:checked + label div:last-child {
  top: 6px;
  transform: rotateZ(45deg);
}

#menu_checkbox:checked + label div:nth-child(2) {
  width: 85px;
  top: 0;
  right: 13px;
  transform: rotateZ(-45deg);
}
