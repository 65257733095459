.skills-component .skills-content {
  padding: 12px 0px 12px 0px;

  margin: 0;
  max-width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: 15px;
}

.skills-component .skill-entry {
  /* background-color: #292929; */
  display: flex;
  gap: 10px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 1.5em;
  padding: 10px;

  /* padding-right: 30px; */
}

.skills-component .skill-entry span {
  font-family: "SourceSansProSemiBold", sans-serif;
  /* color: #666666;
  color: #d4d4d4; */
}

.skills-component .skill-entry .icon {
  /* color: #666666; */
  width: 25px;
  height: 25px;
  align-self: flex-end;
  transform: translate(14px, 13px) rotate(-45deg);
}

.skills-component .skill-entry .icon.git {
  transform: translate(14px, 13px) rotate(0deg);
}

@media (min-width: 768px) {
  .skills-component .skills-content {
    justify-content: flex-start;
    max-width: 500px;
  }
}
